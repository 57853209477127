<template>
  <div class="container-fluid" v-if="!loading">
    <div class="row">
      <div class="col-md-3 spark-settings-tabs">
        <billing-nav :user="user" :path="path"></billing-nav>
      </div>
      <div class="col-md-9">
        <div class="card border-0 shadow-sm">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col">
                <h4>Billing History</h4>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Invoice Amount</th>
                    <th scope="col">Amount Paid</th>
                    <th scope="col">Status</th>
                    <th scope="col" class="text-center">Invoice</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                          v-for="invoice in invoices"
                          :key="invoice.id"
                  >
                    <th scope="row">{{ invoice.created_at | formatDate }}</th>
                    <td>£{{ invoice.amount_due.toFixed(2) }}</td>
                    <td>£{{ invoice.amount_paid.toFixed(2) }}</td>
                    <td class="text-uppercase">
                      <i v-if="invoice.status === 'paid'" class="far fa-check-circle me-1 text-success"></i>
                      <i v-else class="far fa-times-circle me-1 text-danger"></i>
                      {{ invoice.status }}
                    </td>
                    <td class="text-center">
                      <i class="fa fa-file-invoice cursor-pointer text-primary" @click="generateInvoice(invoice)"></i>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BillingNav from "../partials/BillingNav";
export default {
  props: [],
  data() {
    return {
      loading: true,
      invoices: []
    }
  },
  components: {
    BillingNav,
  },
  methods: {
    generateInvoice(invoice){
      this.$axios.get(process.env.VUE_APP_API_URL + "/billing/generate-invoice/" + invoice.id).then(({ data }) => {
        window.location = data;
      });
    },
    fetchInvoices() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/billing/fetch-invoices", ).then(({ data }) => {
        this.invoices = data;
        this.loading = false;
      });
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$router.currentRoute.fullPath;
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    }
  },
  mounted() {
    this.fetchInvoices();
  }
};
</script>

<style>
</style>